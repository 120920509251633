// preCheck.js
//


import axios from 'axios';
import { regNumberOrVinIsGood, hasProperty, getTimeStamp } from './util';


// Send a limited (suppea) request to API to check the regNumber is found
// 
export default function preCheck(regNumber, vin, regType, timeout = 15000) {

  // console.log('preCheck');

  return new Promise(async (resolve, reject) => {
    if (!regNumberOrVinIsGood(regNumber, vin)) {
      // console.error(getTimeStamp(), 'preLookup, regNumber not good');
      reject({
        success: false,
        message: 'Rekisterinumero virheellinen'
      });
      return;
    }

    const tempRegNumber = regNumber.length > 0 ? regNumber : 'x';
    const tempRegType = regType.length > 0 ? '?regType=' + regType : '';
    const tempVin = vin.length > 0 ? '&vin=' + vin : '';

    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_LIMITED}${tempRegNumber}${tempRegType}${tempVin}`, { timeout });
      if (hasProperty(response.data, "virhekoodi")) {
        // console.error(getTimeStamp(), 'preLookup returns false');
        reject({
          success: false,
          message: 'Rekisterinumeroa ei löydy'
        });
      } else {
        // console.log(getTimeStamp(), 'preLookup returns true');
        resolve({
          success: true
        });
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.error(getTimeStamp(), 'Request canceled:', error.message);
      } else if (error.code === 'ECONNABORTED') {
        console.error(getTimeStamp(), 'Request timeout:', error.message);
      } else {
        console.error(getTimeStamp(), 'Error fetching data from API:', error.message);
      }
      reject({
        success: false,
        message: 'Muu virhe kyselyssä' + ' (preCheck 53)',
        details: error.message
      });
    }
  });
}



