// Zircon - Ask component

import React from "react"
import { Card, CardBody, CardTitle } from 'reactstrap';
import { msg } from './message';

function Ask(props) {

  return (
    <div>
      <div className="container">
        <div className="row align-items-start">
          <div className="col-12 col-md-8 mx-auto">
            <Card as="a" onClick={props.validatePayment} style={{ cursor: "pointer" }}>
              <CardBody>
                <CardTitle>{msg('Kysy-meiltä', props.lng)}</CardTitle>
                <div className="container">
                  <br></br>
                  {msg('Kysy-meiltä-teksti', props.lng)}
                  <br></br>
                </div>
             </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ask