// Zircon - RegNumber component

import React, { useState, useEffect } from "react"
import { Button, Form, FormGroup, Label, Input, Col } from 'reactstrap'
import { Card, CardImg, CardFooter, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
// import FontAwesome from 'react-fontawesome';

// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { msg } from './message';
// import { getTimeStamp } from './util';


function RegNumber({ regNumber, agree, handleAgreeChange, handleRegNumberChange, validateRegNumber, vin, handleVinChange, regType, handleRegTypeChange, promoToken, handlePromoTokenChange, moreOptions, setMoreOptions, lng }) {

  // const [moreOptions, setMoreOptions] = useState(false);
  const [showAgreement, setShowAgreement] = useState(false);

  const toggleMoreOptions = () => setMoreOptions(!moreOptions);
  const toggleShowAgreement = () => setShowAgreement(!setShowAgreement);

  // console.log(getTimeStamp(), 'RegNumber');

  // useEffect(() => {
  //   setAppStatus('input regNumber');
  // }, []);

  useEffect(() => {
    // console.log('showAgreement:', showAgreement);
  }, [showAgreement]);

  const radioOptions = [
    //{ value: '1', icon: 'car', label: msg('auto', lng) },
    { value: '1', label: msg('auto', lng) },
    { value: '2', label: msg('mp', lng) },
    { value: '3', label: msg('traktori', lng) },
    { value: '4', label: msg('maastoajoneuvo', lng) },
    { value: '5', label: msg('perävaunu', lng) },
    { value: '7', label: msg('siirtolupa', lng) },
    { value: '8', label: msg('vientirekisteröinti', lng) },
    { value: '0', label: msg('mopo', lng) },
  ];

  // console.log('RegNumberForm ', regNumber)

  return (
    <div className='container'>
      <div className="row row-content">
        <div className="col-12 col-md-3 m-1">
        </div>
        <div className="col-12 col-md-5 m-1">
          <Form onSubmit={validateRegNumber}>
            <FormGroup row>
              {/* regNumber input */}
              <Label htmlFor="regnumber" md={{ size: 12 }}><strong>{msg('Kirjoita rekisterinumero', lng)}</strong></Label>
              <Col md={4}>
                <Input type="text" id="regnumber" name="regnumber"
                  value={regNumber}
                  onChange={handleRegNumberChange} />
              </Col>

              {/* search button */}
              <Col md={{ size: 4 }}>
                <Button color="primary" >
                  {msg('Hae auton tiedot', lng)}
                </Button>
              </Col>

              {/* agree checkbox */}
              <Col md={{ size: 4 }}>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox"
                      name="agree"
                      checked={agree}
                      onChange={handleAgreeChange} /> {' '}
                    {msg('Hyväksyn käyttöehdot', lng)}
                  </Label>
                </FormGroup>
                <FormGroup>
                  <button
                    type="button"
                    className="link-button"
                    onClick={() => setShowAgreement(!showAgreement)}
                  >
                    {msg('Näytä käyttöehdot', lng)}
                  </button>
                </FormGroup>
              </Col>

              {/* modal for showing agreement */}
              <Modal isOpen={showAgreement} toggle={toggleShowAgreement}>
                <ModalHeader toggle={toggleShowAgreement}>{msg('Käyttöehdot-Trafi', lng)}</ModalHeader>
                <ModalBody>
                  {msg('Käyttöehdot-Trafi-teksti', lng)}
                </ModalBody>
                <ModalHeader toggle={toggleShowAgreement}>{msg('Käyttöehdot-muu', lng)}</ModalHeader>
                <ModalBody>
                  {msg('Käyttöehdot-muu-teksti', lng)}
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggleShowAgreement}>{msg('Sulje ikkuna', lng)}</Button>
                </ModalFooter>
              </Modal>

              {/* more options */}
              <Col md={{ size: 4 }}>
                <a href="#" onClick={toggleMoreOptions}>
                  {msg('Lisää hakuehtoja', lng)}
                </a>
              </Col>

            </FormGroup>
          </Form>

          {/* more search options */}
          {moreOptions && (
            <Form onSubmit={validateRegNumber}>
              <FormGroup row>
                {/* VIN input */}
                <Label htmlFor="vin" md={{ size: 12 }}><strong>{msg('valmistenumero', lng)}</strong></Label>
                <Col md={6}>
                  <Input type="text" id="vin" name="vin"
                    value={vin}
                    onChange={handleVinChange} />
                </Col>

                {/* Additional radio buttons section */}
                <Col md={12}>
                  <FormGroup tag="fieldset">
                    {/* <legend>{msg('Valitse vaihtoehto', lng)}</legend> */}
                    <Label htmlFor="regType" md={{ size: 12 }}><strong>{msg('ajoneuvolaji', lng)}</strong></Label>

                    {radioOptions.map((option, index) => (
                      <FormGroup check inline key={option.value}>
                        <Label check>
                          <Input
                            type="radio"
                            name="radioOption"
                            value={option.value}
                            checked={regType === option.value}
                            onChange={handleRegTypeChange}
                          />{' '}
                          {/* <FontAwesome name={option.icon} />  */}
                          {option.label}
                          {/* <FontAwesome name={option.icon} /> {option.label} */}
                          </Label>
                      </FormGroup>
                    ))}
                  </FormGroup>
                </Col>

                {/* token or promo code input */}
                <Label htmlFor="token" md={{ size: 12 }}><strong>{msg('Syötä promokoodi', lng)}</strong></Label>
                <Col md={6}>
                  <Input type="text" id="token" name="token"
                    value={promoToken}
                    onChange={handlePromoTokenChange} />
                </Col>
              </FormGroup>
            </Form>
          )}

          {/* image */}
          <div className="row justify-content-evenly ">
            <div className="col-12 m-5">
              <Card className="border-0">
                <CardImg src='./key.png' alt="" />
                <CardFooter>Väliaikainen kuva {lng}</CardFooter>
              </Card>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
}


export default RegNumber