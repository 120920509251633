// Zircon - Terms component

import React from "react"
import { Card, CardBody, CardTitle } from 'reactstrap';
import { msg } from './message';

function Terms(props) {

  return (
    <div>
      <div className="container">
        <div className="row align-items-start">
          <div className="col-12 col-md-8 mx-auto">
            <Card as="a" onClick={props.validatePayment} style={{ cursor: "pointer" }}>
              <CardBody>
                <CardTitle>{msg('Käyttöehdot-Trafi', props.lng)}</CardTitle>
                <div className="container">
                  <br></br>
                  {msg('Käyttöehdot-Trafi-teksti', props.lng)}
                  <br></br>
                </div>
                <br></br>
                <CardTitle>{msg('Käyttöehdot-muu', props.lng)}</CardTitle>
                <div className="container">
                  <br></br>
                  {msg('Käyttöehdot-muu-teksti', props.lng)}
                </div>              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Terms